import { useCurrentWorkspace } from "../useCurrentWorkspace";

export const useWorkspaceParam = <T>(paramPath: string) => {
  const workspace = useCurrentWorkspace();
  const pramValue = ref<T | null>(null);
  const keys = paramPath.split('.');
  const params = workspace.value?.params;

  if (params) {
    let current: any = params;

    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      if (!current[key]) {
        current[key] = {};
      }
      current = current[key] as any;
    }

    pramValue.value = current[keys[keys.length - 1]];
  }

  return pramValue;
};
